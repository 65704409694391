import { DownOutlined, ShoppingCartOutlined } from '@ant-design/icons';  
import { Menu } from 'antd';  
import { Link } from 'react-router-dom';  

function MainMenu() {  
    return (  
        <>  
             <Menu  
            theme="dark"  
            mode="horizontal"  
            style={{   
                backgroundColor: 'blue',   
                display: 'flex',   
                padding: '0 20px', // Add horizontal padding  
                lineHeight: '64px', // Set line height for vertical centering  
            }}  
        >   
                <Menu.Item key="/products" icon={<ShoppingCartOutlined />}>
                    <Link to="/products">Products</Link>
                </Menu.Item>
                <Menu.SubMenu
                    title="Services"
                    icon={<DownOutlined />}
                >
                    <Menu.Item key="32">
                        <Link to="/services?category=32">Dietician / Nutritionist</Link>
                    </Menu.Item>
                    <Menu.Item key="31">
                        <Link to="/services?category=31">Skin care</Link>
                    </Menu.Item>
                    <Menu.Item key="28">
                        <Link to="/services?category=28">Weight Loss</Link>
                    </Menu.Item>
                </Menu.SubMenu>
                
                <Menu.SubMenu  
                    title="Resources"  
                    style={{ width: '11em' }}  
                    icon={<DownOutlined />}  
                >  
                    {/* Apps */}
                    <Menu.SubMenu title="Apps">
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://balanceapp.com/',
                                }}
                                target="_blank"
                            >
                                Balanceapp
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://www.headspace.com/home?_stsgnoredir=1',
                                }}
                                target="_blank"
                            >
                                Headspace
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'http://healthymindsapp.ca/',
                                }}
                                target="_blank"
                            >
                                Healthymindsapp
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://insighttimer.com/',
                                }}
                                target="_blank"
                            >
                                Insighttimer
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://www.smilingmind.com.au/',
                                }}
                                target="_blank"
                            >
                                Smilingmind
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    {/* Calculating */}
                    <Menu.SubMenu title="Calculators & Screening Tools">
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/bac-calculator.html',
                                }}
                                target="_blank"
                            >
                                Blood Alcohol Concentration (BAC) Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/bmr-calculator.html',
                                }}
                                target="_blank"
                            >
                                BMR Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/bmi-calculator.html',
                                }}
                                target="_blank"
                            >
                                BMI Calculator
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/diabetic.html"}}target="_blank">CVD risk calculator (Framingham)</Link></Menu.Item> */}

                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/body-fat-calculator.html',
                                }}
                                target="_blank"
                            >
                                Body Fat Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/body-type-calculator.html',
                                }}
                                target="_blank"
                            >
                                Body Type Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://bcrisktool.cancer.gov/calculator.html',
                                }}
                                target="_blank"
                            >
                                Breast cancer risk assessment tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/calorie-calculator.html',
                                }}
                                target="_blank"
                            >
                                Calorie Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/calories-burned-calculator.html',
                                }}
                                target="_blank"
                            >
                                Calories Burned Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://clincalc.com/cardiology/stroke/chads.aspx',
                                }}
                                target="_blank"
                            >
                                CHADS2 Calculator for Guiding Antithrombotic Treatment in Atrial
                                Fibrillation{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.mycanceriq.ca/Cancers/Cervical',
                                }}
                                target="_blank"
                            >
                                Cervical cancer risk assessment tool{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{ pathname: 'https://ccrisktool.cancer.gov/calculator.html' }}
                                target="_blank"
                            >
                                Colorectal Cancer Risk Assessment Tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/due-date-calculator.html',
                                }}
                                target="_blank"
                            >
                                Due Date Calculator{' '}
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/index.html?sex=F&age=64"}}target="_blank">Framingham CVD calculator</Link></Menu.Item> */}
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://frax.shef.ac.uk/FRAX/tool.aspx?country=19',
                                }}
                                target="_blank"
                            >
                                FRAX, Osteoporotic fracture risk calculator
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/GeneralCalculators.jsp"}}target="_blank">General conversions</Link></Menu.Item> */}
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://clincalc.com/cardiology/anticoagulation/hasbled.aspx',
                                }}
                                target="_blank"
                            >
                                HAS-BLED Calculator for Assessing Bleeding Risk in Atrial
                                Fibrillation{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.mycanceriq.ca/Cancers/Kidney',
                                }}
                                target="_blank"
                            >
                                Kidney cancer risk assessment tool{' '}
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/kidneyFailureRiskCalculator/kidneyFailureRiskCalculator.jsp?sex=F&age=64&demographic_no=1327"}}target="_blank">Kidney failure risk calculator </Link></Menu.Item> */}
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/lean-body-mass-calculator.html',
                                }}
                                target="_blank"
                            >
                                Lean Body Mass Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://analysistools.cancer.gov/lungCancerRiskAssessment/#/',
                                }}
                                target="_blank"
                            >
                                Lung Cancer Risk Assessment Tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/tdee-calculator.html',
                                }}
                                target="_blank"
                            >
                                Macronutrient Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.mycanceriq.ca/Cancers/Melanoma',
                                }}
                                target="_blank"
                            >
                                Melanoma risk assessment tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/lean-body-mass-calculator.html',
                                }}
                                target="_blank"
                            >
                                Osteoporotic Fracture risk calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/ovulation-calculator.html',
                                }}
                                target="_blank"
                            >
                                Ovulation Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/period-calculator.html',
                                }}
                                target="_blank"
                            >
                                Period Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.mcw.edu/calculators/pregnancy-date',
                                }}
                                target="_blank"
                            >
                                Pregnancy calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/macro-calculator.html',
                                }}
                                target="_blank"
                            >
                                Simple calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/tdee-calculator.html',
                                }}
                                target="_blank"
                            >
                                Total Daily Energy Expenditure (TDEE) Calculator
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/index.html?sex=F&age=48"}}target="_blank">Framingham </Link></Menu.Item> */}
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/kidneyFailureRiskCalculator/kidneyFailureRiskCalculator.jsp?sex=F&age=48&demographic_no=6955" }}target="_blank">Kidney failure risk calculator</Link></Menu.Item> */}
                    </Menu.SubMenu>
                    

                    {/* worksheet */}
                    <Menu.SubMenu title="Worksheets">
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://docs.google.com/document/d/1RBPbCwlxUNZKwqHu1YTLGSWWpDW43k6L/edit',
                                }}
                                target="_blank"
                            >
                                Dr. Vala’s death bed Q’S
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://docs.google.com/document/d/1SNrFCZHsz8acUM2OdxHuJTXnJt8Eb13j/edit',
                                }}
                                target="_blank"
                            >
                                Short Term Productivity Planner
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://docs.google.com/document/d/1hRU6doeyjZe0IkvZc26ShrxynGVWa-O5/edit',
                                }}
                                target="_blank"
                            >
                                The WHY Worksheet
                            </Link>
                        </Menu.Item>


                    </Menu.SubMenu>
                </Menu.SubMenu>
            </Menu>
        </>
    )
}

export default MainMenu
