import { Result, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.less';
import AppLayout from './components/layout/app-layout';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Login from './pages/auth/login';
import LoginWpEmail from './pages/auth/login/loginwpemail';
import NormalLoginPage from './pages/auth/normal/NormalLoginPage';
import RegisterPage from './pages/auth/register/RegisterPage';
import ForgotPassword from './pages/auth/resetpassword/forgotpassword';
import ResetPassword from './pages/auth/resetpassword/ResetPassword';
import WheelChartPage from './pages/charts/wheel-chart/WheelChartPage';
import CoreValuesPage from './pages/forms/core-values';
import MyHealthWellnessData from './pages/forms/my-health-wellness';
import MyLifeMasteryPage from './pages/forms/my-life-masetry';
import MyLifeMasteryRoadMapPage from './pages/forms/my-life-mastery-road-map';
import MyLifeWheelPage from './pages/forms/my-life-wheel';
import Unauthorized from './pages/results/Unauthorized';
import UserPointsPage from './pages/tables/user-points/UserPointsPage';
import AllProductsPage from './pages/products/AllProductsPage';
import userProfile from './pages/user/userProfile';
import MyGoalsPage from './pages/forms/my-goals';
import { autoLoginUser } from './store/actions/authActions';
import ProductDetailPage from './pages/products/ProductDetailPage';
import OscarRegisterPage from './pages/auth/register/OscarRegisterPage';
import NormalLoginPageReferal from './pages/auth/normal/NormalLoginPageReferal';
import AllServicesPage from './pages/products/AllServicesPage';
import resourceslist from './pages/resources/resources';
import AppsPage from './pages/resources/appsPage';
import CalculatorsPage from './pages/resources/calculatorsPage';
import DocumentsPage from './pages/resources/documentsPage';
import WorksheetsPage from './pages/resources/workSheets';
const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(autoLoginUser());
  }, [dispatch]);

  if (auth.loading) {
    return (
      <Result icon={<Spin size="large" />} title="Loading, please wait..." />
    );
  }

  return (
    <div className="App">
      <AppLayout>
        <Switch>
          <ProtectedRoute path="/" exact component={WheelChartPage} />
          <ProtectedRoute
            path="/profile/points"
            exact
            component={UserPointsPage}
          />
          <ProtectedRoute path="/my-life" exact component={MyLifeMasteryPage} />
          <ProtectedRoute path="/my-goals" exact component={MyGoalsPage} />

          <ProtectedRoute
            path="/core-values"
            exact
            component={CoreValuesPage}
          />
          <ProtectedRoute
            path="/my-road"
            exact
            component={MyLifeMasteryRoadMapPage}
          />
          <ProtectedRoute path="/wheel" exact component={MyLifeWheelPage} />
          <ProtectedRoute
            path="/my-health-wellness"
            exact
            component={MyHealthWellnessData}
          />

          <ProtectedRoute path="/products" exact component={AllProductsPage} />
          <ProtectedRoute path="/services" exact component={AllServicesPage} />

          <ProtectedRoute path="/resources" exact component={resourceslist} />
          <ProtectedRoute path="/resources/Apps" exact component={AppsPage} />
          <ProtectedRoute path="/resources/calculators" exact component={CalculatorsPage} />
          <ProtectedRoute path="/resources/Documents" exact component={DocumentsPage} />
          <ProtectedRoute path="/resources/Worksheets" exact component={WorksheetsPage} />

          <ProtectedRoute
            path="/products/:id"
            component={ProductDetailPage}
            exact
          />
          <ProtectedRoute
            path="/profile"
            component={userProfile}
            exact
          />

          <Route exact path="/otherlogin" component={Login} />
          <Route exact path="/loginWPEmail" component={LoginWpEmail} />
          <Route exact path="/login" component={NormalLoginPage} />
          <Route exact path="/loginreferal" component={NormalLoginPageReferal} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/oscarregister" component={OscarRegisterPage} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />

          <Route path="/unauthorized" exact component={Unauthorized} />
          <Route render={() => <h1>404 - Not Found!</h1>} />
        </Switch>
      </AppLayout>
    </div>
  );
};

export default App;
