import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import PageTitle from '../../components/layout/page-title/PageTitle';
import './ResourcesList.css'; // Import custom CSS for hover animation

const { Title } = Typography;

const ResourcesList = () => {
    // Define the resources with their titles and links
    const resources = [
        {
            title: 'Apps',
            description: 'Explore useful applications for your needs.',
            link: '/resources/apps',
        },
        {
            title: 'Calculators and Screening Tools',
            description: 'Access tools for calculations and screenings.',
            link: '/resources/calculators',
        },
        {
            title: 'Documents',
            description: 'Find important documents and resources.',
            link: '/resources/documents',
        },
        {
            title: 'Worksheets',
            description: 'Download and use worksheets for your tasks.',
            link: '/resources/worksheets',
        },
    ];

    return (
        <>
            <PageTitle title="Resources" />
            <div style={{ marginBottom: '50px', padding: '24px' }}>
                <Title level={3}>Explore Resources</Title>
                <Row gutter={[16, 16]}>
                    {resources.map((resource, index) => (
                        <Col key={index} xs={24} sm={12} md={12} lg={6}>
                            <Card
                                hoverable
                                onClick={() => (window.location.href = resource.link)}
                                className="resource-card" // Add a custom class for hover animation
                                style={{
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.3s ease', // Smooth transition
                                }}
                            >
                                <Title level={4} style={{ color: 'inherit' }}>
                                    {resource.title}
                                </Title>
                                <p style={{ color: 'inherit' }}>{resource.description}</p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default ResourcesList;