// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AppsPage.css */
.app-card {
    background-color: #ffffff;
    /* Default background color */
    color: #333;
    /* Default text color */
    border: 1px solid #e8e8e8;
    /* Light border */
}

.app-card:hover {
    background-color: #6e44ff;
    /* Purple background on hover */
    color: #ffffff;
    /* White text on hover */
    transform: translateY(-5px);
    /* Slight lift effect */
    box-shadow: 0 8px 16px rgba(110, 68, 255, 0.3);
    /* Purple shadow */
    transition: all 0.3s ease;
    /* Smooth transition for all properties */
}`, "",{"version":3,"sources":["webpack://./src/pages/resources/AppsPage.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,+BAA+B;IAC/B,cAAc;IACd,wBAAwB;IACxB,2BAA2B;IAC3B,uBAAuB;IACvB,8CAA8C;IAC9C,kBAAkB;IAClB,yBAAyB;IACzB,yCAAyC;AAC7C","sourcesContent":["/* AppsPage.css */\n.app-card {\n    background-color: #ffffff;\n    /* Default background color */\n    color: #333;\n    /* Default text color */\n    border: 1px solid #e8e8e8;\n    /* Light border */\n}\n\n.app-card:hover {\n    background-color: #6e44ff;\n    /* Purple background on hover */\n    color: #ffffff;\n    /* White text on hover */\n    transform: translateY(-5px);\n    /* Slight lift effect */\n    box-shadow: 0 8px 16px rgba(110, 68, 255, 0.3);\n    /* Purple shadow */\n    transition: all 0.3s ease;\n    /* Smooth transition for all properties */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
