import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import {
    CalculatorOutlined,
    HeartOutlined,
    FireOutlined,
    DashboardOutlined,
    ClockCircleOutlined,
    PieChartOutlined,
    LineChartOutlined,
    BarChartOutlined,
    AreaChartOutlined,
    DotChartOutlined,
} from '@ant-design/icons';
import PageTitle from '../../components/layout/page-title/PageTitle';
import './CalculatorsPage.css'; // Import custom CSS for hover animation

const { Title } = Typography;

const CalculatorsPage = () => {
    // Define the tools with their titles, links, and icons
    const tools = [
        {
            title: 'Blood Alcohol Concentration (BAC) Calculator',
            link: 'https://www.calculator.net/bac-calculator.html',
            icon: <CalculatorOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'BMR Calculator',
            link: 'https://www.calculator.net/bmr-calculator.html',
            icon: <FireOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'BMI Calculator',
            link: 'https://www.calculator.net/bmi-calculator.html',
            icon: <DashboardOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Body Fat Calculator',
            link: 'https://www.calculator.net/body-fat-calculator.html',
            icon: <PieChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Body Type Calculator',
            link: 'https://www.calculator.net/body-type-calculator.html',
            icon: <LineChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Breast Cancer Risk Assessment Tool',
            link: 'https://bcrisktool.cancer.gov/calculator.html',
            icon: <HeartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Calorie Calculator',
            link: 'https://www.calculator.net/calorie-calculator.html',
            icon: <FireOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Calories Burned Calculator',
            link: 'https://www.calculator.net/calories-burned-calculator.html',
            icon: <ClockCircleOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'CHADS2 Calculator for Guiding Antithrombotic Treatment in Atrial Fibrillation',
            link: 'https://clincalc.com/cardiology/stroke/chads.aspx',
            icon: <BarChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Cervical Cancer Risk Assessment Tool',
            link: 'https://www.mycanceriq.ca/Cancers/Cervical',
            icon: <AreaChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Colorectal Cancer Risk Assessment Tool',
            link: 'https://ccrisktool.cancer.gov/calculator.html',
            icon: <DotChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Due Date Calculator',
            link: 'https://www.calculator.net/due-date-calculator.html',
            icon: <ClockCircleOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'FRAX, Osteoporotic Fracture Risk Calculator',
            link: 'https://frax.shef.ac.uk/FRAX/tool.aspx?country=19',
            icon: <CalculatorOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'HAS-BLED Calculator for Assessing Bleeding Risk in Atrial Fibrillation',
            link: 'https://clincalc.com/cardiology/anticoagulation/hasbled.aspx',
            icon: <HeartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Kidney Cancer Risk Assessment Tool',
            link: 'https://www.mycanceriq.ca/Cancers/Kidney',
            icon: <AreaChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Lean Body Mass Calculator',
            link: 'https://www.calculator.net/lean-body-mass-calculator.html',
            icon: <PieChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Lung Cancer Risk Assessment Tool',
            link: 'https://analysistools.cancer.gov/lungCancerRiskAssessment/#/',
            icon: <LineChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Macronutrient Calculator',
            link: 'https://www.calculator.net/tdee-calculator.html',
            icon: <FireOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Melanoma Risk Assessment Tool',
            link: 'https://www.mycanceriq.ca/Cancers/Melanoma',
            icon: <DotChartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Ovulation Calculator',
            link: 'https://www.calculator.net/ovulation-calculator.html',
            icon: <ClockCircleOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Period Calculator',
            link: 'https://www.calculator.net/period-calculator.html',
            icon: <ClockCircleOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Pregnancy Calculator',
            link: 'https://www.mcw.edu/calculators/pregnancy-date',
            icon: <HeartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Simple Calculator',
            link: 'https://www.calculator.net/macro-calculator.html',
            icon: <CalculatorOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Total Daily Energy Expenditure (TDEE) Calculator',
            link: 'https://www.calculator.net/tdee-calculator.html',
            icon: <FireOutlined style={{ fontSize: '32px' }} />,
        },
    ];

    return (
        <>
            <PageTitle title="Calculators & Screening Tools" />
            <div style={{ marginBottom: '50px', padding: '24px' }}>
                <Title level={3}>Explore Calculators & Screening Tools</Title>
                <Row gutter={[16, 16]}>
                    {tools.map((tool, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                            <Card
                                hoverable
                                onClick={() => window.open(tool.link, '_blank')} // Open link in new tab
                                className="tool-card" // Add a custom class for hover animation
                                style={{
                                    height: '180px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.3s ease', // Smooth transition
                                }}
                            >
                                <div style={{ marginBottom: '16px' }}>{tool.icon}</div>
                                <Title level={4} style={{ color: 'inherit', margin: 0, fontSize: '16px' }}>
                                    {tool.title}
                                </Title>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default CalculatorsPage;