import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import {
    AppstoreOutlined,
    CalculatorOutlined,
    SmileOutlined,
    HeartOutlined,
    ClockCircleOutlined,
    FireOutlined,
    DashboardOutlined,
    RocketOutlined,
    CloudOutlined,
} from '@ant-design/icons';
import PageTitle from '../../components/layout/page-title/PageTitle';
import './AppsPage.css'; // Import custom CSS for hover animation

const { Title } = Typography;

const AppsPage = () => {
    // Define the apps with their titles, links, and icons
    const apps = [
        {
            title: 'Balanceapp',
            link: 'https://balanceapp.com/',
            icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Calorieking',
            link: 'https://www.calorieking.com/',
            icon: <CalculatorOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Headspace',
            link: 'https://www.headspace.com/home?_stsgnoredir=1',
            icon: <SmileOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Healthymindsapp',
            link: 'http://healthymindsapp.ca/',
            icon: <HeartOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Insighttimer',
            link: 'https://insighttimer.com/',
            icon: <ClockCircleOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Loseit',
            link: 'https://loseit.com/',
            icon: <FireOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'My Fitness Pal',
            link: 'https://myfitnesspal.com/',
            icon: <DashboardOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Noom',
            link: 'https://noom.com/',
            icon: <RocketOutlined style={{ fontSize: '32px' }} />,
        },
        {
            title: 'Smilingmind',
            link: 'https://www.smilingmind.com.au/',
            icon: <CloudOutlined style={{ fontSize: '32px' }} />,
        },
    ];

    return (
        <>
            <PageTitle title="Apps" />
            <div style={{ marginBottom: '50px', padding: '24px' }}>
                <Title level={3}>Explore Apps</Title>
                <Row gutter={[16, 16]}>
                    {apps.map((app, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                            <Card
                                hoverable
                                onClick={() => window.open(app.link, '_blank')} // Open link in new tab
                                className="app-card" // Add a custom class for hover animation
                                style={{
                                    height: '180px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.3s ease', // Smooth transition
                                }}
                            >
                                <div style={{ marginBottom: '16px' }}>{app.icon}</div>
                                <Title level={4} style={{ color: 'inherit', margin: 0 }}>
                                    {app.title}
                                </Title>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default AppsPage;