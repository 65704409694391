import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import {
    FileTextOutlined,
    FileWordOutlined,
    FilePdfOutlined,
} from '@ant-design/icons';
import PageTitle from '../../components/layout/page-title/PageTitle';
import './WorksheetsPage.css'; // Import custom CSS for hover animation

const { Title } = Typography;

const WorksheetsPage = () => {
    // Define the worksheets with their titles, links, and icons
    const worksheets = [
        {
            title: "Dr. Vala’s Death Bed Q’s",
            link: "https://docs.google.com/document/d/1RBPbCwlxUNZKwqHu1YTLGSWWpDW43k6L/edit",
            icon: <FileTextOutlined style={{ fontSize: '32px' }} />,
            type: "external", // Indicates the link opens in a new tab
        },
        {
            title: "S+R=O Worksheet",
            link: "/resources/Podcast series.docx",
            icon: <FileWordOutlined style={{ fontSize: '32px' }} />,
            type: "download", // Indicates the link is a download
        },
        {
            title: "Short Term Productivity Planner",
            link: "https://docs.google.com/document/d/1SNrFCZHsz8acUM2OdxHuJTXnJt8Eb13j/edit",
            icon: <FileTextOutlined style={{ fontSize: '32px' }} />,
            type: "external",
        },
        {
            title: "The WHY Worksheet",
            link: "https://docs.google.com/document/d/1hRU6doeyjZe0IkvZc26ShrxynGVWa-O5/edit",
            icon: <FileTextOutlined style={{ fontSize: '32px' }} />,
            type: "external",
        },
    ];

    return (
        <>
            <PageTitle title="Worksheets" />
            <div style={{ marginBottom: '50px', padding: '24px' }}>
                <Title level={3}>Explore Worksheets</Title>
                <Row gutter={[16, 16]}>
                    {worksheets.map((worksheet, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                            <Card
                                hoverable
                                onClick={() => {
                                    if (worksheet.type === "external") {
                                        window.open(worksheet.link, '_blank'); // Open external link in new tab
                                    } else if (worksheet.type === "download") {
                                        const link = document.createElement('a');
                                        link.href = worksheet.link;
                                        link.download = worksheet.title; // Set the download filename
                                        link.click(); // Trigger the download
                                    }
                                }}
                                className="worksheet-card" // Add a custom class for hover animation
                                style={{
                                    height: '180px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.3s ease', // Smooth transition
                                }}
                            >
                                <div style={{ marginBottom: '16px' }}>{worksheet.icon}</div>
                                <Title level={4} style={{ color: 'inherit', margin: 0, fontSize: '16px' }}>
                                    {worksheet.title}
                                </Title>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default WorksheetsPage;