import React from "react";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const AnswersChart = ({ data }) => {
  const CHART_COLOR = "#a700ba";
  const CHART_HEIGHT = 300;

  return (
    <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => moment(date).format("YYYY/MM/DD")}
          tick={{ fontSize: 12 }}
        />
        <YAxis domain={[0, "auto"]} tick={{ fill: "#666", fontSize: 12 }} />
        <Tooltip
          content={({ payload }) => {
            if (payload && payload.length > 0) {
              return (
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px 10px",
                    border: "1px solid #ddd",
                  }}
                >
                  {payload[0].payload.value.toFixed(1)}
                </div>
              );
            }
            return null;
          }}
        />
        <Line
          type="line"
          dataKey="value"
          stroke={CHART_COLOR}
          strokeWidth={2}
          dot={{
            r: 4,
            fill: "white",
            stroke: CHART_COLOR,
            strokeWidth: 2,
          }}
          activeDot={{
            r: 6,
            fill: CHART_COLOR,
            stroke: "white",
            strokeWidth: 2,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AnswersChart;
